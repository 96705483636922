.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.listExam .list-item {
  background-color: #fafafa;
  padding: 1rem 0;
  margin-bottom: 0.5rem;
}
.listExam .list-item .subject {
  margin-left: 1rem;
  margin-right: 1.5rem;
}
.listExam .list-item .subject .subject-title {
  display: flex;
}
.listExam .list-item .subject .subject-title .number {
  padding-left: 1rem;
}
.listExam .list-item .subject .option {
  display: flex;
  padding: 0.75rem;
  justify-content: space-between;
}
.listExam .list-item .subject .option .radioGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-content: center;
}
.listExam .list-item .subject .option .radioGroup label {
  margin: 3px 0;
  display: flex;
  justify-content: flex-start;
}
.listExam .list-item .subject .option .radioGroup .el-radio__label {
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  line-height: 20px;
}
.searchbox h3 {
  font-size: 0.8rem;
  position: relative;
  margin-bottom: 1.4rem;
}
.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.rowJsontitle > div {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.rowJsontitle > div img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.searchbox .el-button.is-disabled,
.searchbox .el-button.is-disabled:focus,
.searchbox .el-button.is-disabled:hover {
  background: #a9a9a9;
  border-color: #a9a9a9;
  color: #fff;
}
.changetreestyle .treeinput .el-input {
  flex: 0;
}
.treeinput .el-form-item__content {
  width: 36rem;
}
.el-checkbox__inner {
  width: 18px;
  height: 18px;
}
.el-checkbox__inner:after {
  height: 10px;
  left: 6px;
}
